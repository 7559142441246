import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Restore Sanity
import RenderSections from "../components/sanity-v2/RenderSections"

const IndexPage = ({ data }) => {
  const sanityContent = data.sanityPage
  return (
    <Layout>
      <SEO
        title="Top Coding School in Vietnam"
        baseUrl=""
        openGraphImage={sanityContent.openGraphImage}
        description={sanityContent.description}
      />
      <RenderSections sections={sanityContent.content} />
    </Layout>
  )
}

// The fragments that we reuse are defined in src/fragments.js.

export const query = graphql`
  query {
    sanityPage(pageType: { eq: "index" }) {
      title
      description
      openGraphImage {
        ...SanityImageFields
      }
      content {
        ... on SanityDemoProject {
          _type
          _key
          heading {
            vi
            en
          }
          subtitle {
            _key
            _type
            _rawEn
            _rawVi
          }
          textColor
          backgroundColor
          youTubeId
          name {
            vi
            en
          }
          profilePic {
            ...SanityImageFields
          }
          pic1 {
            ...SanityImageFields
          }
          pic2 {
            ...SanityImageFields
          }
          pic3 {
            ...SanityImageFields
          }
          description {
            _key
            _type
            _rawEn
            _rawVi
          }
        }
        ...heroFields
        ...whyChooseFields
        ...companiesListFields
        ...accordionFields
        ...superSectionFields
        ... on SanityCoursesList {
          _type
          _key
          cta {
            openNewTab
            link
            size
            title {
              en
              vi
            }
          }
          description {
            en
            vi
          }
          heading {
            en
            vi
          }
        }
        ... on SanityEventsList {
          _type
          _key
          numEvents
          heading {
            vi
            en
          }
        }
        ... on SanityBlogsList {
          _type
          _key
          heading {
            vi
            en
          }
        }
        ... on SanityEmployeesList {
          _type
          _key
          heading {
            en
            vi
          }
          description {
            en
            vi
          }
        }
        ... on SanityCtas {
          _key
          _type
          ctas {
            openNewTab
            link
            size
            title {
              en
              vi
            }
          }
        }
        ... on SanityHighlightFeaturesList {
          _key
          _type

          highlightFeatures {
            _key
            ...highlightFeatureFields
          }
        }
        ... on SanityGraduateProjectsList {
          _key
          _type
          graduateProjects {
            ...projectFields
          }

          heading {
            en
            vi
          }
        }
        ... on SanityNotification {
          _key
          _type
          _rawEnbody
          _rawVibody
        }
        ... on SanityGraduateVideosList {
          _type
          _key
          numVideos
          heading {
            en
            vi
          }
        }
      }
    }
  }
`

export default IndexPage
